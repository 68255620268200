// stores/auth.js

import { defineStore } from "pinia";
import axios from "axios"; // Ensure axios is installed and configured for your backend base URL

export const useAuthStore = defineStore("auth", {
  state: () => ({
    email: "",
    name: "",
    organization: "",
    how_did_you_find_us: "",
    password: "",
    token: localStorage.getItem("accessToken") || "",
    errorMessage: null,
  }),

  actions: {
    async step1Email() {
      this.errorMessage= null;
      try {
        const response = await axios.post("/auth/register/step1/", {
          email: this.email,
        });
        this.token = response.data.token; // Store the token for step 2
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail ||
          "empty or invalid format of email";
      }
    },

    // Step 2: Complete registration with user details
    async completeRegistration() {
      this.errorMessage= null;

      try {
        const response = await axios.post("/auth/register/step2/", {
          name: this.name,
          organization: this.organization,
          how_did_you_find_us: this.how_did_you_find_us,
          password: this.password,
          token: this.token,
        });
        if (response.data.access_token) {
          this.token = response.data.access_token;
          localStorage.setItem("accessToken", this.token);
        }
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail ||
          "empty fields";
      }
    },
    async login() {
      this.errorMessage= null;

      this.loading = true;
      this.errorMessage = null; // Reset error message
      try {
        const response = await axios.post("/auth/login/", {
          email: this.email,
          password: this.password,
        });

        this.token = response.data.access_token; // Store the access token
        this.email = ""; // Clear the email and password fields for security
        this.password = "";
        // make login persistant and set token in local storage
        localStorage.setItem("accessToken", this.token);
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Invalid email or password";
      } finally {
        this.loading = false;
      }
    },
    async passwordResetRequest() {
      this.errorMessage= null;

      this.loading = true;
      this.errorMessage = null;
      try {
        const response = await axios.post("/auth/password-reset/", {
          email: this.email,
        });
        alert(response.data.message);
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to send reset email";
      } finally {
        this.loading = false;
      }
    },

    // Password reset confirmation action
    async passwordResetConfirm(uid, token, newPassword) {
      this.errorMessage= null;

      this.loading = true;
      this.errorMessage = null;
      try {
        await axios.post(`/auth/password-reset/${uid}/${token}/`, {
          new_password: newPassword,
        });
        alert("Password has been reset successfully"); // Notify user of successful reset
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to reset password";
      } finally {
        this.loading = false;
      }
    },
  },
});
