<template>
  <div class="fixed flex justify-center items-end  bottom-0 right-0 mb-10 w-3/4 h-4/5">
    <div
      class="animate-fade-up text-white  w-full h-4/5 m-24 mb-32 bg-gray-800 rounded-xl border border-gray-950 shadow-2xl shadow-black">
      orders
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped></style>
