import { defineStore } from "pinia";
import artist from "../artist.json";

export const useSongStore = defineStore("song", {
  state: () => ({
    isPlaying: false,
    audio: null,
    currentArtist: null,
    currentTrack: null,
  }),
  actions: {
    loadSong(path) {
      if (this.audio && this.audio.src) {
        this.audio.pause();
        this.isPlaying = false;
        this.audio.src = path;
      }

      this.audio = new Audio();
      this.audio.src = path;

      this.currentArtist = this.audio;
      this.currentTrack = this.audio;
      setTimeout(() => {
        this.isPlaying = true;
        this.audio.play();
      }, 200);
    },

    playOrPauseSong() {
      console.log(this.audio);
      if (this.audio.paused) {
        this.isPlaying = true;
        this.audio.play();
      } else {
        this.isPlaying = false;
        this.audio.pause();
      }
    },

    playOrPauseThisSong(path) {
      if (!this.audio || !this.audio.src) {
        this.loadSong(path);
        return;
      }

      this.playOrPauseSong();
    },

    prevSong(currentTrack) {
      let track = artist.tracks[currentTrack.id - 2];
      this.loadSong(artist, track);
    },

    nextSong(currentTrack) {
      if (currentTrack.id === artist.tracks.length) {
        let track = artist.tracks[0];
        this.loadSong(artist, track);
      } else {
        let track = artist.tracks[currentTrack.id];
        this.loadSong(artist, track);
      }
    },

    playFromFirst(path) {
      this.resetState();
      this.loadSong(path);
    },

    resetState() {
      this.isPlaying = false;
      this.audio = null;
      this.currentArtist = null;
      this.currentTrack = null;
    },
  },
  persist: true,
});
