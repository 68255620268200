<template>
  <div class="page-container">
    <div class="content-container" >
      <div class="logo"  >
        <h1>
          <img
            style="vertical-align: middle;"
            src="@/assets/logo.svg"
            alt="typeteam logo"
            class="icon"
          />
          typeteam
        </h1>
        <h2 style="color: #ffffff; font-size: 20px">Reset Password</h2>
        <p style="color: #8d8d8d">
          Enter the email address you registered with and we'll send you
          instructions to reset your password.
        </p>
      </div>
      <div class="form">
        <label for="email" class="label">Email</label>
        <input
          type="email"
          v-model="authStore.email"
          placeholder="Your Email"
          class="input-field"
        />
      </div>

      <button @click="sendResetInstructions" class="sign-in-button">
        Submit
      </button>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from '@/stores/auth';
import '@/styles/login.css';
import { useRouter } from 'vue-router'; 


const router = useRouter(); 
const authStore = useAuthStore();

const sendResetInstructions = async () => {
  
  router.push('/reset-password-two');
  await authStore.passwordResetRequest();
};
</script>
