import "./index.css";
import { createApp } from "vue"; // Import createApp from Vue 3
import { createPinia } from "pinia"; // Import Pinia
import App from "./App.vue"; // Import the root component
import PrimeVue from "primevue/config";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import ToastService from "primevue/toastservice";
// import KeyboardShortcuts from "./plugins/keyboardShortcuts";
import axios from "axios";

import router from "./router"; // Import the router

import Aura from "@primevue/themes/aura";
import "primeicons/primeicons.css";

// axios.defaults.baseURL = 'https://typeteam.liara.run/api'; // Replace with your actual backend URL
axios.defaults.baseURL = "https://typeteam.gozar.team/api"; // Replace with your actual backend URL

// Create the Vue application instance
const app = createApp(App);

// app.use(KeyboardShortcuts);
// Setup PrimeVue
app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      darkModeSelector: ".dark",
      cssLayer: {
        name: "primevue",
        order: "tailwind-base, primevue, tailwind-utilities",
      },
    },
  },
});
// Create a Pinia store instance
const pinia = createPinia();

// Use the Pinia store
app.use(pinia);
app.use(ToastService);

// Use the router
app.use(router);

app.use(PrimeVue);
app.component("primeDialog", Dialog);
app.component("primeInputText", InputText);
app.component("primeButton", Button);
app.component("primeDropdown", Dropdown);
// Mount the Vue application
app.mount("#app");
