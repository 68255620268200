<template>
  <Toast />
  <div class="login-container">
    <div class="left-container">
      <div class="form-wrapper">
        <div class="logo">
          <h1><img style="vertical-align: middle;" src="@/assets/logo.svg" alt="typeteam logo" class="icon" />typeteam</h1>
          <p><a href="/sign-up" class="arrow-link">
            <span class="arrow">&#8592;</span> Back
          </a></p>
          <h2 style='color:#FFFFFF;'>Sign up | Step 2 </h2>
          <p class="account-text">Please provide the required details.</p>
        </div>

        <div class="form">
          <label for="Name" class="label">Name</label> 
          <input type="text" v-model="authStore.name" placeholder="Your Name" class="input-field" />

          <label for="Organisation" class="label">Organisation <span class="optional">(Optional)</span></label> 
          <input type="text" v-model="authStore.organization" placeholder="Organisation Name" class="input-field" />

          <label for="find-out" class="label">How did you find out about us?</label>
          <select id="find-out" v-model="authStore.how_did_you_find_us" class="input-field">
            <option value="social_media">Social Media</option>
            <option value="google">Google</option>
            <option value="friend">Friend</option>
            <option value="other">Other</option>
          </select>

          <label for="password" class="label">Password</label> 
          <input type="password" v-model="authStore.password" :class="['input-field', { 'input-error': isPasswordInvalid }]" placeholder="Choose a strong password" />

          <div class="password-requirements">
            <p><strong>Password should contain:</strong></p>
            <ul>
              <li>At least 8 characters</li>
              <li>Upper and lower case letters</li>
              <li>A symbol (@$%)</li>
            </ul>
          </div>
          <div class="terms">
            <p>By signing up, you agree to TypeTeam’s <span class="highlight">Terms of Service</span> and 
            <span class="highlight">Privacy Policy</span></p>
          </div>

          <button @click="completeSignUp" class="sign-in-button">
            Complete sign up &#8594;
          </button>
        </div>
      </div>
    </div>

    <div class="right-container">
      <img src="@/assets/laptop-woman.png" alt="Woman with laptop" class="side-image" />
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router'; 
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { ref } from 'vue';

const authStore = useAuthStore();
const router = useRouter(); 
const toast = useToast();
const isPasswordInvalid = ref(false);

const validatePassword = (password) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$%]).{8,}$/;
  return passwordRegex.test(password);
};

const completeSignUp = async () => {
  if (!validatePassword(authStore.password)) {
    isPasswordInvalid.value = true;
    toast.add({ severity: 'error', summary: 'Sign Up Error', detail: 'Password constraints should be satisfied', life: 3000 });
    return;
  }

  isPasswordInvalid.value = false;
  await authStore.completeRegistration();

  if (authStore.errorMessage) {
    toast.add({ severity: 'error', summary: 'Sign Up Error', detail: authStore.errorMessage, life: 3000 });
  } else {
    router.push('/admin');
  }
};
</script>

<style scoped>

.input-error {
  border-color: #FB432C;
  border-width: 2px;
}
</style>
