<template>
  <DashboardBody>

      <!-- Custom Tab Navigation -->
      <ul class="flex space-x-4 border-b border-gray-700 mb-5">
        <li
          v-for="item in items"
          :key="item.label"
          @click="activeTab = item.label"
          :class="['cursor-pointer', activeTab === item.label ? 'text-white border-b-2 border-blue-400' : 'text-gray-400']"
        >
          {{ item.label }}
        </li>
      </ul>

      <!-- Tab Content -->
  <div v-if="activeTab === 'Users Management'" class="mt-5 py-5 px-9">
    <div class="flex justify-between items-center mb-3">
      <div>
        <SearchBar :onChange="search" placeholder="Search People" />
      </div>
      <Button
        label="Add Users"
        icon="pi pi-plus pr-2"
        class="p-button-primary bg-blue-600"
        style="padding: 10px 20px  !important;"
        @click="showDialog = true" 
      />
    </div>

    <DataTable :value="users" class="p-datatable-sm text-sm">
      <Column header=" " style="width: 5em">
        <template #body="{ data }">
          <img :src="require('@/assets/profilepic.jpg')" :alt="`${data.name}'s Profile Picture`" class="rounded-full w-10 h-10" />
        </template>
      </Column>
      <Column field="name" header="Name" />
      <Column field="email" header="Email" />
      <Column field="role" header="Access Level" />
      <Column header="Action">
        <template #body>
          <Button
            icon="pi pi-ellipsis-v"
            aria-haspopup="true"
            aria-controls="overlay_menu2"
            @click="toggleAction"
            class="p-button p-button-text text-white bg-blue-600 w-8 h-8"
          />
          <Menu
            class="dark text-sm w-min bg-gray-900 !text-gray-100 border-gray-800"
            ref="actionState2"
            id="overlay_menu2"
            :model="actionItems"
            :popup="true"
          />
        </template>
      </Column>
    </DataTable>

    <InviteDialog v-model:visible="showDialog" />
  </div>

  <div v-if="activeTab === 'Your Profile'" class="profile-container flex flex-col items-left space-y-8 py-5 px-9">
    <!-- Profile Picture and Change/Delete Buttons -->
    <div class="profile-image-section flex items-center space-x-4">
      <img :src="require('@/assets/profilepic.jpg')" alt="Profile Picture" class="profile-picture w-12 h-12 rounded-full object-cover" />
      <Button label="Change Photo" icon="pi pi-pencil pr-2" class="p-button-secondary bg-slate-700 text-white border-transparent" style="padding:5px !important;"/>
      <Button icon="pi pi-trash" class="p-button-danger bg-red-400" />
    </div>

    <!-- Profile Form -->
    <div class="profile-details w-full max-w-md space-y-4 items-left">
      <div class="p-field w-full">
        <label for="name" class="block  text-white text-left text-sm pb-2">Name</label>
      <InputGroup class="w-full py-2 px-4 shadow-2xl border border-gray-400/70 shadow-black/60 bg-gray-900 rounded-xl">
        <InputText class=" border-none  px-2 text-gray-400 placeholder:text-gray-300/50 bg-transparent w-full " />

      </InputGroup>      </div>

      <div class="p-field w-full">
        <label for="lastName" class="block text-white text-left text-sm pb-2">Last Name</label>
      <InputGroup class="w-full py-2 px-4 shadow-2xl border border-gray-400/70 shadow-black/60 bg-gray-900 rounded-xl">
      <InputText class=" border-none  px-2 text-gray-400 placeholder:text-gray-300/50 bg-transparent w-full " />

      </InputGroup>
        </div>

      <div class="p-field w-full">
        <label for="email" class="block text-white text-left text-sm pb-2">Email</label>
      <InputGroup class="w-full py-2 px-4 shadow-2xl border border-gray-400/70 shadow-black/60 bg-gray-900 rounded-xl">
        <InputText class=" border-none  px-2 text-gray-400 placeholder:text-gray-300/50 bg-transparent w-full " />

      </InputGroup>
      </div>

      <div class="flex justify-start">
        <Button 
          label="Save changes" 
          class="p-button-secondary bg-blue-600 text-white border-transparent" 
          style="padding:5px !important;"
        />
      </div>
    </div>
  </div>
<div v-if="activeTab === 'Workspace'" class="workspace-settings p-6 py-5 px-9">
  <h2 class="text-sm text-left text-white mb-3">Workspace</h2>
  <p class="text-gray-400 mb-6 text-sm text-left">Manage your workspace settings</p>

  <!-- Workspace Logo -->
  <div class="workspace-logo flex items-center mb-8 ">
    <img :src="require('@/assets/fender.png')" alt="Workspace Logo" class="w-16 h-16 rounded-md mr-4" />
    <Button label="Change Photo" icon="pi pi-pencil pr-2" class="p-button-secondary bg-slate-700 text-white border-transparent mr-2" style="padding: 10px 15px !important;" />
    <Button icon="pi pi-trash" class="p-button-danger bg-red-400" style="padding: 10px 15px !important;" />
  </div>

  <!-- Workspace Name -->
  <div class="mb-8 items-start flex flex-col">
    <label for="workspaceName" class="block text-gray-400 text-sm text-left mb-2 pb-2">Workspace Name</label>
      <InputGroup class="w-200 py-2 px-4 shadow-2xl border border-gray-400/70 shadow-black/60 bg-gray-900 rounded-xl">
        <InputText class=" border-none  px-2 text-gray-400 placeholder:text-gray-300/50 bg-transparent w-full " />

      </InputGroup>
      </div>

  <!-- Save Changes Button -->
  <div class="mb-8 items-start flex flex-col">
    <Button label="Save Changes" class="p-button-primary bg-blue-600 text-white border-transparent" style="padding: 10px 20px !important;" />
  </div>

  <!-- Delete Workspace -->
  <div class="delete-workspace mb-8 items-start flex flex-col">
    <h3 class="text-lg text-white mb-2">Delete Workspace</h3>
    <p class="text-gray-400 mb-4 text-left">
      If you remove your workspace, all its contents will be permanently erased. You will then be redirected to another workspace you are part of, or to the workspace creation page if you aren't part of any others.
    </p>
    <Button label="Delete Workspace" icon="pi pi-trash pr-2" class="p-button-danger bg-red-400" style="padding: 10px 20px !important;" />
  </div>
</div>

<div v-if="activeTab === 'Pricing'" class="mt-5 py-5 px-9">
  <div class="flex justify-between items-center mb-3">
    <Button label="Add Plans" icon="pi pi-plus pr-2" class="p-button-primary bg-blue-600" style="padding: 10px 20px !important;" />
  </div>

  <DataTable :value="plans" class="p-datatable-sm text-sm">
    <Column field="plan" header="Plans" />
    <Column field="basePrice" header="Base Price" />
    <Column field="discountedPrice" header="Discounted Price" />
    <Column header="Actions" style="width: 5em">
      <template #body>
               <Button 
                  icon="pi pi-ellipsis-v" 
                  aria-haspopup="true" 
                  aria-controls="overlay_menu2" 
                  @click="toggleAction" 
                  class="p-button p-button-text text-white bg-blue-600 w-8 h-8"
                
                />
        <Menu class="dark text-sm w-min bg-gray-900 !text-gray-100 border-gray-800" ref="actionState2" id="overlay_menu3" :model="actionItems2" :popup="true" />
      </template>
    </Column>
  </DataTable>
</div>
<div v-if="activeTab === 'Customizations'" class="mt-5 py-5 px-9">
  <!-- Logo Sign Section -->
  <div class="mb-8">
    <h3 class="text-white text-sm mb-2 text-left">Logo Sign</h3>
    <div class="flex items-center space-x-4">
      <img :src="require('@/assets/fender.png')" alt="Logo Sign" class="w-24 h-24 rounded-md object-cover" />
      <Button label="Change Photo" icon="pi pi-pencil pr-2" class="p-button-secondary bg-slate-700 text-white border-transparent" style="padding: 10px 15px !important;" />
      <Button icon="pi pi-trash" class="p-button-danger bg-red-400" style="padding: 10px 15px !important;" />
    </div>
  </div>

  <!-- Full Logo Section -->
  <div class="mb-8">
    <h3 class="text-white text-sm mb-2 text-left">Full Logo</h3>
    <div class="flex items-center space-x-4">
      <img :src="require('@/assets/fender.png')" alt="Full Logo" class="w-40 h-20 rounded-md object-cover" />
      <Button label="Change Photo" icon="pi pi-pencil pr-2" class="p-button-secondary bg-slate-700 text-white border-transparent" style="padding: 10px 15px !important;" />
      <Button icon="pi pi-trash" class="p-button-danger bg-red-400" style="padding: 10px 15px !important;" />
    </div>
  </div>

  <!-- Background Color Section -->
  <div class="mb-8">
    <h3 class="text-white text-sm mb-2 text-left">Background Color</h3>
    <div class="flex items-center space-x-4">
      <div class="w-12 h-12 rounded-md bg-white"></div>
      <Button label="Change Color" icon="pi pi-pencil pr-2" class="p-button-secondary bg-slate-700 text-white border-transparent" style="padding: 10px 15px !important;" />
    </div>
  </div>

  <!-- Primary Color Section -->
  <div class="mb-8">
    <h3 class="text-white text-sm mb-2 text-left">Primary Color</h3>
    <div class="flex items-center space-x-4">
      <div class="w-12 h-12 rounded-md bg-blue-600"></div>
      <Button label="Change Color" icon="pi pi-pencil pr-2" class="p-button-secondary bg-slate-700 text-white border-transparent" style="padding: 10px 15px !important;" />
    </div>
  </div>

  <!-- Secondary Color Section -->
  <div class="mb-8">
    <h3 class="text-white text-sm mb-2 text-left">Secondary Color</h3>
    <div class="flex items-center space-x-4">
      <div class="w-12 h-12 rounded-md bg-yellow-500"></div>
      <Button label="Change Color" icon="pi pi-pencil pr-2" class="p-button-secondary bg-slate-700 text-white border-transparent" style="padding: 10px 15px !important;" />
    </div>
  </div>
</div>

<div v-if="activeTab === 'Payment'" class="mt-5 py-5 px-9">
  <!-- PayPal Section -->
  <div class="flex justify-between items-center mb-4">
    <div class="flex items-center space-x-3">
      <i class=" text-white text-3xl"></i>
      <span class="text-white text-lg">PayPal</span>
    </div>
    <Button label="Manage" class="p-button-primary bg-blue-600" style="padding: 10px 20px !important;" />
  </div>

  <!-- Divider -->
  <hr class="border-gray-700 mb-4">

  <!-- Stripe Section -->
  <div class="flex justify-between items-center">
    <div class="flex items-center space-x-3">
      <i class="pi pi-stripe text-white text-3xl"></i>
      <span class="text-white text-lg">Stripe</span>
    </div>
    <Button label="Connect" class="p-button-secondary bg-slate-700 text-white border-transparent" style="padding: 10px 20px !important;" />
  </div>
</div>

<div v-if="activeTab === 'Coupons'" class="mt-5 py-5 px-9">
  <div class="flex justify-between items-center mb-3">
    <Button label="Add Coupon" icon="pi pi-plus pr-2" class="p-button-primary bg-blue-600" style="padding: 10px 20px !important;" />
  </div>

  <DataTable :value="coupons" class="p-datatable-sm text-sm">
    <Column field="couponCode" header="Plans" />
    <Column field="percentage" header="Percentage" />
    <Column header="Actions" style="width: 5em">
      <template #body>
               <Button 
                  icon="pi pi-ellipsis-v" 
                  aria-haspopup="true" 
                  aria-controls="overlay_menu2" 
                  @click="toggleAction" 
                  class="p-button p-button-text text-white bg-blue-600 w-8 h-8"
                
                />
        <Menu class="dark text-sm w-min bg-gray-900 !text-gray-100 border-gray-800" ref="actionState2" id="overlay_menu4" :model="actionItems3" :popup="true" />
      </template>
    </Column>
  </DataTable>
</div>

  </DashboardBody>

</template>

<script setup>
import { ref } from "vue";
import Menu from 'primevue/menu';
import InputText from 'primevue/inputtext';
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import SearchBar from '@/components/SearchBar.vue';
import DashboardBody from '@/components/DashboardBody.vue';
import InviteDialog from '@/components/InviteDialog.vue';

const actionState2 = ref();
const toggleAction = (event) => {
  actionState2.value.toggle(event);
};

const actionItems = ref([
  {
    items: [
      { label: 'Edit', icon: 'pi pi-pencil' },
      { label: 'Change Access', icon: 'pi pi-key' },
      { label: 'Delete User', icon: 'pi pi-trash' },
    ]
  }
]);

const actionItems2 = ref([
  {
    items: [
      { label: 'Edit', icon: 'pi pi-pencil' },
      { label: 'Delete Plan', icon: 'pi pi-trash' },
    ]
  }
]);

const actionItems3 = ref([
  {
    items: [
      { label: 'Edit', icon: 'pi pi-pencil' },
      { label: 'Delete Coupon', icon: 'pi pi-trash' },
    ]
  }
]);

const coupons = ref([
  { couponCode: 'BLACKFR', percentage: '30%' },
  { couponCode: 'OFF30', percentage: '30%' },
  { couponCode: 'GSGSEAW', percentage: '30%' },
]);

const plans = ref([
  { plan: 'Standard', basePrice: '$12', discountedPrice: '-' },
  { plan: 'Speedy', basePrice: '$20', discountedPrice: '$18' },
  { plan: 'Expedite', basePrice: '$34', discountedPrice: '$30' },
]);

const activeTab = ref("Users Management");

const items = [
  { label: "Users Management" },
  { label: "Your Profile" },
  { label: "Workspace" },
  { label: "Pricing" },
  { label: "Customizations" },
  { label: "Payment" },
  { label: "Coupons" },
];

const users = ref([
  { name: "yasi Daroodi", email: "yasi@fender.com", role: "Owner" },
  { name: "yasi Daroodi", email: "yasi@fender.com", role: "Reviewer" },
  { name: "yasi Daroodi", email: "yasi@fender.com", role: "Manager" },
  { name: "yasi Daroodi", email: "yasi@fender.com", role: "Editor" },
]);

// State to control InviteDialog visibility
const showDialog = ref(false);
</script>



<style scoped>
ul {
  background-color: transparent;
  padding: 10px;
}

li {
  padding: 10px;
}

li.active {
  border-bottom: 2px solid white !important;
  color: white;
}

li:hover {
  color: white;
}

img {
  border-radius: 50%;
}
</style>
