<template>
  <div class="card flex justify-between items-center w-full mt-9">
    <img :src="user.image" alt="" class="w-14 rounded-2xl" />
    <div class="w-full flex flex-col items-start justify-center p-4">
      <div class="text-lg font-bold">{{ user.workspace }}</div>
      <div class="text-md text-gray-400/60">{{ user.name }}</div>
    </div>
    <Button
      class="border-none bg-transparent hover:bg-gray-400 rounded-full transition"
      type="button"
      icon="pi pi-angle-down"
      label=""
      @click="toggle"
    />

    <Popover
      ref="op"
      class="bg-gray-900 border-gray-700 shadow-black shadow-2xl text-white"
    >
      <div class="flex bg-gray-900 flex-col gap-4 w-[20rem]">
        <div>
          <span class="font-medium block mb-2">Share this document</span>
          <InputGroup class="p-3">
            <InputText value="https://primevue.org/" readonly class="w-[25rem]">
            </InputText>
            <InputGroupAddon>
              <i class="pi pi-copy"></i>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <div>
          <span class="font-medium block mb-2">Invite Member</span>
          <InputGroup class="p-3">
            <InputText disabled />
            <Button label="Invite" icon="pi pi-users"></Button>
          </InputGroup>
        </div>
        <div>
          <span class="font-medium block mb-2">Team Members</span>
          <ul class="list-none p-0 m-0 flex flex-col gap-4">
            <li
              v-for="member in members"
              :key="member.name"
              class="flex items-center gap-2"
            >
              <img
                :src="`https://primefaces.org/cdn/primevue/images/avatar/${member.image}`"
                style="width: 32px"
              />
              <div>
                <span class="font-medium">{{ member.name }}</span>
                <div class="text-sm text-surface-500 dark:text-surface-400">
                  {{ member.email }}
                </div>
              </div>
              <div
                class="flex items-center gap-2 text-surface-500 dark:text-surface-400 ml-auto text-sm"
              >
                <span>{{ member.role }}</span>
                <i class="pi pi-angle-down"></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Popover>
  </div>
</template>

<script setup>
import { effect, ref } from "vue";
import Popover from "primevue/popover";
import InputGroupAddon from "primevue/inputgroupaddon";
import InputGroup from "primevue/inputgroup";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useWorkspaceStore } from "@/stores/workspace";
import { useAuthStore } from "@/stores/auth";

const workspaceStore = useWorkspaceStore();
const authStore = useAuthStore();
const op = ref();
const members = ref([
  {
    name: "Amy Elsner",
    image: "amyelsner.png",
    email: "amy@email.com",
    role: "Owner",
  },
  {
    name: "Bernardo Dominic",
    image: "bernardodominic.png",
    email: "bernardo@email.com",
    role: "Editor",
  },
  {
    name: "Ioni Bowcher",
    image: "ionibowcher.png",
    email: "ioni@email.com",
    role: "Viewer",
  },
]);

const toggle = (event) => {
  op.value.toggle(event);
};

const user = ref({
  image:
    "https://as1.ftcdn.net/v2/jpg/03/39/45/96/1000_F_339459697_XAFacNQmwnvJRqe1Fe9VOptPWMUxlZP8.jpg",
  workspace: "Fender",
  name: "Ali Baghery",
});
effect(() => {
  user.value.workspace = workspaceStore.title;
  user.value.name = authStore.name;
  user.value.name = "mamad";
});
</script>
