<template>
  <div class="p-11">


    <primeDialog
      header="  Invite Users"
      v-model:visible="isVisible"
      class="custom-dialog"
      :modal="true"
      @hide="emitClose"
    >
          <p class="description" style="margin-top:0 !important;">Add colleagues and collaborators to your workspace</p>

      <div>
        <div class="input-button-wrapper">
          <div class="input-wrapper">
            <div class="combined-input">
              <primeInputText id="email" v-model="email" placeholder="Name or Email" class="input-textt" />
              <primeDropdown v-model="role" :options="roles" class="embedded-dropdown" placeholder="Editor" />
            </div>
          </div>

          <primeButton label="Invite" class="invite-button" @click="inviteUser" />
        </div>

        <p class="description">Type or paste multiple emails separated by comma (,)</p>

        <hr class="custom-line" />

        <p class="dialog-title">Invite Link</p>
        <p class="description">Invite colleagues and collaborators via a link.</p>

        <p class="dialog-sub-title">Guest Link</p>

        <div>
          <div class="input-wrapper">
            <div class="combined-input">
              <primeInputText v-model="guestLink" readonly class="input-textt guest-link-input" />
              <primeButton :class="{'pulse': copiedGuestLink}" label="Copy" @click="copyLink(guestLink, 'guest')" class="transparent-button">
                <template v-slot:icon>
                  <img src="@/assets/copy.svg" alt="Copy Icon" class="copy-icon" />
                </template>
              </primeButton>
            </div>
          </div>

          <p class="dialog-sub-title">Member Link</p>

          <div class="input-wrapper">
            <div class="combined-input">
              <primeInputText v-model="memberLink" readonly class="input-textt guest-link-input" />
              <primeButton :class="{'pulse': copiedMemberLink}" label="Copy" @click="copyLink(memberLink, 'member')" class="transparent-button">
                <template v-slot:icon>
                  <img src="@/assets/copy.svg" alt="Copy Icon" class="copy-icon" />
                </template>
              </primeButton>
            </div>
          </div>
        </div>
      </div>
    </primeDialog>
  </div>
</template>


<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isVisible: this.visible, // Initialize with prop value
      email: '',
      role: null,
      roles: [
        { label: 'Editor', value: 'editor' },
        { label: 'Viewer', value: 'viewer' },
      ],
      guestLink: 'https://yourapp.com/guest',
      memberLink: 'https://yourapp.com/member',
      copiedGuestLink: false,
      copiedMemberLink: false,
    };
  },
  watch: {
    // Watch for changes in the `visible` prop and update `isVisible`
    visible(newValue) {
      this.isVisible = newValue;
    },
  },
  methods: {
    emitClose() {
      this.$emit('update:visible', false); // Notify parent to close the dialog
    },
    inviteUser() {
      console.log(`Inviting user: ${this.email} with role: ${this.role}`);
      this.emitClose(); // Close dialog after inviting
    },
    copyLink(link, type) {
      navigator.clipboard.writeText(link);
      if (type === 'guest') {
        this.copiedGuestLink = true;
      } else {
        this.copiedMemberLink = true;
      }
      setTimeout(() => {
        if (type === 'guest') {
          this.copiedGuestLink = false;
        } else {
          this.copiedMemberLink = false;
        }
      }, 1000);
    },
  },
};
</script>

<style>
.p-field {
  margin-bottom: 1.5rem;
}

.pulse {
  animation: pulse-animation 0.4s ease-in-out;
}

@keyframes pulse-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
</style>
