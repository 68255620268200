function capitalizeFirstLetter(string) {
  if (!string) return ""; // Handle empty or undefined input
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function durationToSecs(string) {
  if (!string) return 0; // Handle empty or undefined input
  const [hours, minutes, seconds] = string.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}

export { capitalizeFirstLetter, durationToSecs };
