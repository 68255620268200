<template>
  <Toast /> <!-- PrimeVue Toast component -->

  <div class="login-container">
    <div class="left-container">
      <div class="form-wrapper">
        <div class="logo">
          <h1><img src="@/assets/logo.svg" alt="typeteam logo" class="icon" />typeteam</h1>
          <h2 style='color:#FFFFFF;'>Sign up</h2>
          <p class="account-text">Already have an account? <a href="/sign-in">Sign in</a></p>
        </div>

        <div class="social-sign-in">
          <button @click="signInWithMeta" class="social-button">
            <img src="@/assets/meta-logo.svg" alt="Meta" class="icon1" />
            Continue with Meta
          </button>
          <button @click="signInWithGoogle" class="social-button">
            <img src="@/assets/google-logo.svg" alt="Google" class="icon1" />
            Continue with Google
          </button>
        </div>

        <div class="divider">
          <span class="line"></span>
          <span class="or-text">or</span>
          <span class="line"></span>
        </div>

        <div class="form">
          <label for="email" class="label">Email</label> 
          <input type="email" v-model="authStore.email" placeholder="Your Email" class="input-field" />
          
          <div class="terms">
            <p>By signing up, you agree to TypeTeam’s <span class="highlight">Terms of Service</span> and 
            <span class="highlight">Privacy Policy</span></p>
          </div>

          <button @click="signUp" class="sign-in-button">Start for free</button>
        </div>
      </div>
    </div>

    <div class="right-container">
      <img src="@/assets/laptop-woman.png" alt="Woman with laptop" class="side-image" />
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import Toast from 'primevue/toast';


const authStore = useAuthStore();
const router = useRouter();
const toast = useToast();

const signUp = async () => {
  await authStore.step1Email();
  if (authStore.errorMessage) {
    toast.add({ severity: 'error', summary: 'Sign Up Error', detail: authStore.errorMessage, life: 3000 });
  } else {
    router.push('/sign-up-two');
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
