<template>
  <LoadingOverlay v-if="projectStore.is_loading" />
  <div
    class="text-gray-400 z-10 animate-fade-down animate-delay-700 w-full fixed min-w-fit bg-gray-900 right-0 shadow-2xl top-0 h-28 flex items-center"
  >
    <div class="w-1/4 mx-9">a</div>

    <InputGroup
      class="w-[25rem] py-1 shadow-2xl shadow-black bg-gray-800 rounded-xl"
    >
      <InputGroupAddon class="bg-transparent border-none">
        <Search
          width="2em"
          height="2em"
          class="cursor-pointer hover:text-gray-400/60 transition"
        />
      </InputGroupAddon>
      <InputText
        placeholder="A Keyword, Title, Date, Topic ..."
        unstyled
        class="border-none text-gray-400 placeholder:text-gray-300/50 bg-transparent w-full"
      />
      <InputGroupAddon class="border-none px-3 border bg-transparent">
        <p class="bg-black/70 rounded-xl px-4 py-1 mr-3 font-bold">⌘F</p>
      </InputGroupAddon>
    </InputGroup>

    <div class="mx-auto"></div>
    <div
      @click="newProjectVisible = true"
      class="flex items-center justify-center rounded-lg bg-blue-700 hover:bg-blue-500/80 text-gray-300 px-5 py-3 cursor-pointer shadow-black shadow-2xl transition font-bold"
    >
      <Plus />
      <span>New Project</span>
    </div>

    <OverlayBadge
      severity="danger"
      class="mx-9 cursor-pointer hover:text-gray-400/60 transition"
      @click="toggleNotificationDropdown"
    >
      <Bell width="1.5em" height="1.5em" />
    </OverlayBadge>

    <Avatar
      image="https://cdn-icons-png.flaticon.com/512/5556/5556468.png"
      size="large"
      shape="circle"
      class="bg-gray-50 mr-5 cursor-pointer"
    />

    <!-- Render NotificationDropdown -->
    <NotificationDropdown ref="notificationDropdown" />
  </div>

  <!-- new project dialog -->
  <Dialog
    v-model:visible="newProjectVisible"
    :draggable="false"
    :pt="{
      root: 'bg-gray-900 border-none w-fit text-gray-400 p-8 !z-40',
      header: 'mt-5 mx-5',
      content: 'mx-5',
      mask: '!bg-black/50   backdrop-blur-sm',
    }"
    modal
    header="New Project"
  >
    <template #header>
      <div class="inline-flex max-w items-center justify-center gap-2 w-full">
        <Button
          icon="pi pi-arrow-left"
          text
          rounded
          aria-label="Filter"
          :disabled="newProjectState.page < 1"
          @click="newProjectState.page -= 1"
          class="text-gray-500 hover:bg-gray-700 disabled:hover:bg-gray-500/60 disabled:bg-gray-500/60 disabled:text-white/60"
        />
        <span class="font-bold text-2xl mx-auto">New Project</span>
      </div>
    </template>
    <Divider />

    <div
      :class="[
        'w-[35rem] flex flex-col items-center justify-center transition-all',
        newProjectState.page == 0 ? 'animate-fade' : 'hidden',
      ]"
    >
      <div class="w-full">Select a project type</div>
      <div class="flex gap-2 w-full my-4">
        <div
          :class="[
            'cursor-pointer  hover:bg-blue-600/40 !border !border-gray-700 transition flex flex-col items-center justify-center text-gray-500 w-full py-5 rounded-xl font-bold',
            newProjectState.projectType === 'transcription'
              ? 'bg-blue-600 shadow-xl border-blue-300 !text-white'
              : 'border-white/0 shadow-white/0',
          ]"
          @click="newProjectState.projectType = 'transcription'"
        >
          <PasteClipboard width="36" height="36" />
          Transcription
        </div>
        <div
          :class="[
            ' cursor-pointer hover:bg-blue-600/40 !border !border-gray-700  transition flex flex-col items-center justify-center text-gray-500 w-full py-5 rounded-xl font-bold',
            newProjectState.projectType === 'subtitle'
              ? 'bg-blue-600 shadow-xl border-blue-300 !text-white'
              : 'border-white/0 shadow-white/0',
          ]"
          @click="newProjectState.projectType = 'subtitle'"
        >
          <ClosedCaptionsTag width="36" height="36" />
          Subtitle
        </div>
      </div>
      <div class="m-4 w-full justify-end flex">
        <div
          @click="newProjectState.page = 1"
          class="font-bold px-6 py-2 bg-blue-600 text-white rounded-lg cursor-pointer"
        >
          Next Step
        </div>
      </div>
    </div>

    <div
      :class="[
        'w-[60rem]  flex transition-all',
        newProjectState.page == 1 ? 'animate-fade' : 'hidden',
      ]"
    >
      <div class="w-1/2 flex flex-col">
        <div class="w-full text-sm">
          Add more files, Drop your files here, browse files or import from link
        </div>
        <div class="flex w-[90%] justify-between">
          <button
            @click="selectFile"
            class="flex text-sm items-center justify-center gap-2 border rounded-sm py-4 border-gray-700 w-full m-2 hover:bg-gray-800 transition"
          >
            <FolderPlus width="20" height="20" /> Upload from computer
          </button>
          <input
            type="file"
            ref="fileInput"
            @change="onFileChange"
            style="display: none"
          />
          <button
            @click="selectFile"
            class="flex text-sm items-center justify-center gap-2 border rounded-sm py-4 border-gray-700 w-full m-2 hover:bg-gray-800 transition"
          >
            <Internet width="20" height="20" /> Enter link (youtube...)
          </button>
        </div>
        <div class="mt-5 text-sm">Selected Files:</div>
        <div
          v-for="(value, key) in files"
          :key="key"
          class="w-[86%] mx-2 text-sm flex my-2 py-4 px-3 justify-center items-center rounded-sm border border-gray-700 hover:bg-gray-800 transition"
        >
          <Folder />
          <div class="w-full px-3">
            {{ value.name }}
            <ProgressBar
              :value="fileStore.uploadProgress"
              style="height: 6px"
              :class="{ 'hidden': fileStore.uploadProgress === 0 || fileStore.uploadProgress === 100 }"
            />
          </div>
          <Xmark
            :class="[
              fileStore.uploadProgress < 100
                ? 'bg-gray-300/40 text-white/30'
                : 'hover:text-white hover:bg-gray-400 cursor-pointer ',
              'rounded-full transition',
            ]"
          />

          
        </div>
      </div>
      <div class="text-sm justify-start w-1/2 flex flex-col h-full">
        <div class="mb-5">Select a Service</div>
        <div
          :class="[
            'px-3 gap-2  cursor-pointer  hover:bg-blue-600/40 !border !border-gray-700 transition flex items-center justify-start text-gray-500 w-full py-2 rounded-sm font-bold',
            newProjectState.service === 'manual'
              ? 'bg-blue-600 shadow-xl border-blue-300 !text-white'
              : 'border-white/0 shadow-white/0',
          ]"
          @click="newProjectState.service = 'manual'"
        >
          <PasteClipboard width="25" height="25" />
          Manual Transcription
        </div>
        <div
          :class="[
            ' mt-2 px-3 gap-2 cursor-pointer hover:bg-blue-600/40 !border !border-gray-700  transition flex items-center justify-start text-gray-500 w-full py-2 rounded-sm font-bold',
            newProjectState.service === 'automated'
              ? 'bg-blue-600 shadow-xl border-blue-300 !text-white'
              : 'border-white/0 shadow-white/0',
          ]"
          @click="newProjectState.service = 'automated'"
        >
          <AdobeIllustrator width="25" height="25" />
          Start With an Automated Transcription
        </div>
        <div class="my-5">Select the file language</div>
        <Select
          v-model="newProjectState.language"
          class="mb-5"
          :options="langs"
          :pt="{
            dropdown: 'bg-transparent',
            label: 'bg-transparent text-gray-300',
            listContainer: ' !text-gray-300',
            list: '!text-gray-300',
            overlay: 'bg-gray-900',
            root: 'bg-transparent',
            optionLabel: 'text-gray-300',
            option: 'hover:bg-gray-700 ',
          }"
          optionLabel="name"
          placeholder="Select a Language"
        />
        <div class="flex justify-end mb-5">
          <Button
            @click="createProject()"
            class="font-bold disabled:bg-gray-500 disabled:text-white disabled:cursor-default !px-6 !py-2 bg-blue-600 !border-none text-white rounded-lg cursor-pointer"
            :disabled="
              fileStore.is_loading ||
              newProjectState.service === '' ||
              newProjectState.file === null ||
              newProjectState.language === '' ||
              newProjectState.projectType === null
            "
          >
            Create Project
          </Button>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { onMounted, ref, watch } from "vue"; // Import ref from Vue
import Select from "primevue/select";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import InputText from "primevue/inputtext";
import Avatar from "primevue/avatar";
import NotificationDropdown from "@/components/NotificationDropdown.vue"; // Import NotificationDropdown
import OverlayBadge from "primevue/overlaybadge";
import ProgressBar from "primevue/progressbar";
import LoadingOverlay from "./LoadingOverlay.vue";
import {
  Xmark,
  Search,
  FolderPlus,
  Folder,
  Internet,
  Bell,
  Plus,
  PasteClipboard,
  AdobeIllustrator,
  ClosedCaptionsTag,
} from "@iconoir/vue";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Button from "primevue/button";
import { useWorkspaceStore } from "@/stores/workspace";
import { useFileStore } from "@/stores/file";
import { useProjectStore } from "@/stores/project";
import { useRouter } from "vue-router";
const router = useRouter();

// Create a reference for NotificationDropdown
const notificationDropdown = ref(null);
const workspaceStore = useWorkspaceStore();
const fileStore = useFileStore();
const projectStore = useProjectStore();

watch(
  () => projectStore.current_project,
  () => {
    if (projectStore.current_project) {
      router.push(`/editor/${projectStore.current_project.id}`);
    }
  }
);
onMounted(async () => {
  await workspaceStore.fetchWorkspace();
  newProjectState.value.workspace = workspaceStore.id;
});
const files = ref([]);
const fileInput = ref(null);
const selectFile = () => {
  fileInput.value.click(); // Trigger the file input click
};
const onFileChange = async (event) => {
  const file = event.target.files[0];
  if (file) {
    files.value.push(file);
    await fileStore.upload(file, "audio", workspaceStore.id);
    newProjectState.value.file = fileStore.id;
  }
};
const createProject = async () => {
  newProjectVisible.value = false;
  await projectStore.createProject(
    newProjectState.value.workspace,
    newProjectState.value.file,
    files.value[0].name,
    newProjectState.value.service,
    newProjectState.value.language.name
  );
};

// Method to toggle the NotificationDropdown
const toggleNotificationDropdown = (event) => {
  // Accept the event parameter
  if (notificationDropdown.value) {
    notificationDropdown.value.toggleDropdown(event); // Pass the event to toggleDropdown
  }
};
const newProjectVisible = ref(false);
const langs = ref([
  { name: "English", code: "EN" },
  { name: "Spanish", code: "SP" },
  { name: "Persian", code: "FA" },
]);
const newProjectState = ref({
  projectType: null,
  page: 0,
  service: "",
  language: "",
  file: null,
  workspace: null,
});
</script>

<style>
.p-divider {
  --p-divider-border-color: #272b30;
}

.p-select {
  --p-select-option-selected-background: #9ca3af;
  --p-select-option-selected-color: #111111;
}
.p-dialog-close-button {
  @apply hover:bg-gray-700;
}
</style>
