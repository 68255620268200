<template>
  <div
    class="z-40 grid place-items-center inset-0 backdrop-blur-sm fixed overflow-hidden"
    v-if="!props.small"
  >
    <Vue3Lottie
      animationLink="https://cdn.lottielab.com/l/8849mBRoGQHbtm.json"
      width="100%"
      height="200%"
    />
  </div>
  <div class="z-40 grid place-items-center inset-0 fixed" v-if="props.small">
    <div
      class="grid place-items-center w-2/3 h-[50vh] backdrop-blur-sm overflow-hidden"
    >
      <Vue3Lottie
        animationLink="https://cdn.lottielab.com/l/8849mBRoGQHbtm.json"
        width="100%"
        height="250%"
      />
    </div>
  </div>
</template>

<script setup>
import { Vue3Lottie } from "vue3-lottie";
import { defineProps } from "vue";

var props = defineProps({
  small: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" scoped></style>
