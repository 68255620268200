import { defineStore } from "pinia";
import axios from "axios"; // Ensure axios is installed and configured for your backend base URL

export const useWorkspaceStore = defineStore("workspace", {
  state: () => ({
    id: null,
    collaborators: [],
    owner: "",
    permission: "",
    projects: [],
    title: "",
    logo: null,
    created_at: "",
    errorMessage: null,
  }),

  actions: {
    async fetchWorkspace() {
      try {
        const response = await axios.get(`/workspace/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.id = response.data[0].id;
        this.collaborators = response.data[0].collaborators;
        this.owner = response.data[0].owner;
        this.permission = response.data[0].permission;
        this.projects = response.data[0].projects;
        this.title = response.data[0].title;
        this.logo = response.data[0].logo;
        this.created_at = response.data[0].created_at;
        console.log(this.title);
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to fetch workspace";
      }
    },
  },
});
