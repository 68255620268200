<template>
  <LoadingOverlay v-if="isLoading" small />
  <div
    class="flex flex-col items-center justify-between w-full h-full animate-duration-700"
  >
    <!-- saving and errors -->
    <div
      class="w-full fixed z-[100] top-6 text-white animate-fade"
      v-if="savingState == 'saving'"
    >
      <ProgressSpinner style="width: 20px; height: 20px" /> saving ...
    </div>
    <div
      class="w-full fixed z-[100] top-6 text-red-500 animate-fade"
      v-if="savingState == 'network'"
    >
      network error
    </div>
    <!-- header -->
    <EditorHeader />
    <div class="p-10 px-36 w-full h-[80%] flex flex-col items-start">
      <!-- title -->
      <div class="w-full flex">
        <InputText
          :ref="titleInputField"
          @input="updateWidth"
          @change="updateTitle"
          :style="{ width: inputWidth }"
          class="hover:text-gray-300/60 hover:animate-pulse text-6xl w-auto overflow-hidden bg-transparent border-none text-gray-300 font-bold"
          v-model="title"
        />
        <div
          @click="focusInput"
          class="flex h-min p-2 cursor-pointer rounded-lg text-gray-400 text-sm bg-gray-800 hover:bg-gray-700 transition hover:shadow-gray-400/10 shadow-xl shadow-transparent items-center justify-center gap-1 font-bold"
        >
          <EditPencil /> <span>Rename</span>
        </div>
      </div>
      <!-- subheader -->
      <div class="flex gap-4 ml-4 text-gray-300 text-sm mb-9">
        <div class="flex gap-1 items-center">
          <Globe />
          {{ projectStore.language }}
        </div>
        <div class="flex gap-1 items-center">
          <Clock />
          {{ projectStore.duration }}
        </div>
      </div>
      <!-- main editor -->
      <ScrollPanel
        style="width: 100%; height: 80%"
        class="min-h-10 scroll-smooth"
      >
        <div
          ref="scrollContent"
          class="w-full flex flex-col items-center text-gray-300"
        >
          <div
            :class="['w-full flex flex-col items-start animate-fade-up']"
            v-for="(item, index) in captions"
            :ref="(el) => (captionRefs[index] = el)"
            :key="index"
          >
            <div class="flex items-center gap-3 w-full">
              <Avatar
                :label="
                  item.speaker_full.name == '' ? '' : item.speaker_full.name[0]
                "
                v-model="item.speaker_full"
                :class="[
                  'rounded-full transition w-10 h-10',
                  item.speaker_full.name == '' ? 'bg-gray-500' : 'bg-red-500',
                ]"
              />
              <Select
                :pt="{
                  dropdown: 'bg-transparent border-none',
                  label: 'bg-transparent text-gray-300',
                  listContainer: ' !text-gray-300',
                  list: '!text-gray-300',
                  overlay: 'bg-gray-900',
                  root: 'bg-transparent border-none',
                  optionLabel: 'text-gray-300',
                  option: 'hover:bg-gray-500  aria-selected:bg-gray-500 ',
                }"
                v-model="item.speaker_full"
                :highlightOnSelect="false"
                :options="speakers"
                optionLabel="name"
                class="aria-selected:bg-gray-500"
                :modelValue="item.speaker_full"
                @change="onCaptionChange(item)"
                placeholder="Select a Speaker"
              >
                <template #header>
                  <div class="p-3">
                    <InputText
                      class="w-full !p-3 text-xs overflow-hidden bg-transparent text-gray-300/60 font-bold transition hover:text-gray-300/60 hover:animate-pulse"
                      v-model="newSpeaker"
                    />
                    <Button
                      label="Add New"
                      fluid
                      severity="secondary"
                      text
                      size="small"
                      icon="pi pi-plus"
                      class="hover:bg-gray-500 hover:text-gray-300 mt-2"
                      :class="{ 'disabled-button': loading }"
                      @click="addNewSpeaker"
                      :disabled="loading"
                    />
                    <span v-if="loading" class="ml-2 text-gray-300">
                      <i class="pi pi-spinner pi-spin"></i>
                    </span>
                  </div>
                </template>
                <template #option="slotProps">
                  <div class="flex items-center justify-between w-full">
                    <span>{{ slotProps.option.name }}</span>
                    <i
                      class="pi pi-trash cursor-pointer hover:text-red-500 transition ml-2"
                      @click.stop="deleteSpeaker(slotProps.option)"
                    ></i>
                  </div>
                </template>
              </Select>
              <div class="flex flex-col mr-auto">
                <InputText
                  class="w-auto text-xs overflow-hidden bg-transparent border-none text-gray-300/60 font-bold transition hover:text-gray-300/60 hover:animate-pulse"
                  :value="item.start_timestamp"
                  v-model="item.start_timestamp"
                  @change="onCaptionChange(item)"
                />
                <InputText
                  class="w-auto text-xs overflow-hidden bg-transparent border-none text-gray-300/60 font-bold transition hover:text-gray-300/60 hover:animate-pulse"
                  :value="item.end_timestamp"
                  v-model="item.end_timestamp"
                  @change="onCaptionChange(item)"
                />
              </div>
              <div class="mr-10">
                <XmarkCircle
                  class="cursor-pointer hover:text-gray-600 text-gray-300 transition"
                  @click="
                    editorStore.deleteCaption(item);
                    fetchData();
                  "
                />
              </div>
            </div>

            <Textarea
              :class="[
                '!relative w-full !px-10 !py-6 !my-3 overflow-hidden bg-transparent !hover:text-gray-300/60 hover:animate-pulse text-gray-300/60 font-bold !transition-all !duration-1000',
                index == currentPlayingIndex && useSong.isPlaying
                  ? '!border-green-400/60 !shadow-md !shadow-green-400/50 animate-fade-down'
                  : '!border-none shadow-none',
              ]"
              :value="item.text"
              v-model="item.text"
              placeholder="Start Writing..."
              @change="onCaptionChange(item)"
              @click="
                skipToTimestamp(item.start_timestamp);
                scrollToCaption(index);
              "
            />
            <div
              class="absolute top-14 left-2 text-green-400/60 animate-fade-right animate-delay-700"
              v-if="index == currentPlayingIndex && useSong.isPlaying"
            >
              <PlaySolid />
            </div>
            <Divider type="dashed" class="mb-10" />
          </div>
        </div>
      </ScrollPanel>

      <Divider align="center" type="solid" class="my-10 transition">
        <div
          @click="
            addNewCaption();
            scrollToCaption(captions.length);
          "
          class="bg-gray-800 px-4 rounded-2xl cursor-pointer text-gray-300/70 hover:bg-gray-700 transition"
        >
          Add New Subtitle Line
        </div>
      </Divider>
    </div>
    <AudioPlayer />
  </div>
</template>

<script setup>
import ScrollPanel from "primevue/scrollpanel";
import Button from "primevue/button";
import Select from "primevue/select";
import Divider from "primevue/divider";
import Avatar from "primevue/avatar";
import Textarea from "primevue/textarea";
import EditorHeader from "@/components/EditorHeader.vue";
import AudioPlayer from "@/components/AudioPlayer.vue";
import InputText from "primevue/inputtext";
import ProgressSpinner from "primevue/progressspinner";
import { EditPencil, Globe, Clock, XmarkCircle, PlaySolid } from "@iconoir/vue";
import { ref, nextTick, watch, onMounted } from "vue";
import { useSongStore } from "@/stores/audio";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useProjectStore } from "@/stores/project";
import { useEditorStore } from "@/stores/editor";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import { durationToSecs } from "@/utils";
const isLoading = ref(false);
const projectStore = useProjectStore();
const editorStore = useEditorStore();
const router = useRoute();
const id = router.params.id;
const fetchData = async () => {
  isLoading.value = true;
  await projectStore.fetch(id);

  if (projectStore.status == "in_progress") {
    setTimeout(async () => {
      await fetchData();
    }, 1000);
    return;
  }
  await editorStore.fetch(id);
  title.value = projectStore.title;
  if (editorStore.speakers.length > 0) {
    speakers.value = editorStore.speakers;
    currentSpeacker.value = speakers.value[0];
    captions.value = editorStore.captions;
  } else {
    await editorStore.createSpeaker(id, "Salar Aqili");
    await editorStore.fetch(id);
    speakers.value = editorStore.speakers;
    currentSpeacker.value = speakers.value[0];
    captions.value = editorStore.captions;
  }

  const colors = [
    "bg-red-500",
    "bg-green-500",
    "bg-blue-500",
    "bg-yellow-500",
    "bg-purple-500",
    "bg-pink-500",
  ];
  mappedItems.value = speakers.value.map((item) => {
    const color = colors[Math.floor(Math.random() * colors.length)];
    return { ...item, color };
  });
  console.log(mappedItems.value);
  isLoading.value = false;
};
onMounted(() => {
  fetchData().then();
});
const savingState = ref("");
const titleInputField = ref(null);
const focusInput = async () => {
  await nextTick();
  if (titleInputField.value) {
    titleInputField.value.focus();
  } else {
    console.error("Input field is not available");
  }
};
const skipToTimestamp = (timestamp) => {
  if (audio.value == null) {
    useSong.loadSong(projectStore.file);
  }
  audio.value.currentTime = durationToSecs(timestamp);
  audio.value.play();
  useSong.isPlaying = true;
};
const updateTitle = async () => {
  savingState.value = "saving";
  try {
    await projectStore.updateTitle(id, title.value);

    savingState.value = "";
  } catch (e) {
    savingState.value = "network";
  }
};
const title = ref("");
const inputWidth = ref("10ch");

const updateWidth = () => {
  const length = title.value.length;
  inputWidth.value = `${Math.max(length + 1, 5)}ch`;
};
const mappedItems = ref([]);

const currentSpeacker = ref(null);
const newSpeaker = ref(null);
const speakers = ref(editorStore.speakers);
const captions = ref([]);
const scrollContent = ref(null);
const captionRefs = ref([]);
const currentPlayingIndex = ref();
const loading = ref(false);

const addNewSpeaker = async () => {
  if (!newSpeaker.value || newSpeaker.value.trim() === "") {
    return;
  }
  loading.value = true;
  await editorStore.createSpeaker(id, newSpeaker.value);
  await editorStore.fetch(id);
  loading.value = false;
  newSpeaker.value = "";
};
const scrollToCaption = (index) => {
  currentPlayingIndex.value = index;
  const captionElement = captionRefs.value[index];
  if (captionElement && scrollContent.value) {
    scrollContent.value.parentElement.scrollTop = captionElement.offsetTop;
  }
};
const scrollToTime = (secs) => {
  const cap = captions.value.findIndex((c) => {
    const start = durationToSecs(c.start_timestamp);
    const end = durationToSecs(c.end_timestamp);
    return secs >= start && secs <= end;
  });
  scrollToCaption(cap);
};

const onCaptionChange = async (caption) => {
  savingState.value = "saving";
  try {
    await editorStore.updateCaption(caption);

    savingState.value = "";
  } catch (e) {
    savingState.value = "network";
  }
};
const useSong = useSongStore();
let isTrackTimeCurrent = ref(null);
let isTrackTimeTotal = ref(null);
const { audio } = storeToRefs(useSong);
const addNewCaption = async () => {
  savingState.value = "saving";
  useSong.playOrPauseThisSong(projectStore.file);
  try {
    editorStore.createCaption(
      id,
      currentSpeacker.value.id,
      "Edit This ...",
      audio.value.currentTime,
      audio.value.currentTime + 5
    );
    savingState.value = "";
  } catch (e) {
    savingState.value = "network";
  }
  await fetchData();
};
watch(
  () => audio.value,
  () => {
    timeupdate();
    loadmetadata();
  }
);
const timeupdate = () => {
  audio.value.addEventListener("timeupdate", function () {
    scrollToTime(audio.value.currentTime);
    var minutes = Math.floor(audio.value.currentTime / 60);
    var seconds = Math.floor(audio.value.currentTime - minutes * 60);
    isTrackTimeCurrent.value =
      minutes + ":" + seconds.toString().padStart(2, "0");
  });
};

const loadmetadata = () => {
  audio.value.addEventListener("loadedmetadata", function () {
    const duration = audio.value.duration;
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    isTrackTimeTotal.value =
      minutes + ":" + seconds.toString().padStart(2, "0");
  });
};

const deleteSpeaker = async (speaker) => {
  loading.value = true;
  await editorStore.deleteSpeaker(speaker.id);
  await editorStore.fetch(id);
  loading.value = false;
};

watch(
  () => editorStore.speakers,
  () => {
    speakers.value = editorStore.speakers;
    currentSpeacker.value = speakers.value[0];
    captions.value = editorStore.captions;
  }
);
</script>

<style>
:root {
  --p-divider-content-background: #00000000;
  --p-divider-border-color: #9ca3af !important;
}
.p-select {
  --p-select-option-selected-background: #9ca3af !important;
  --p-select-option-selected-color: #111111 !important;
}

.disabled-button {
  background-color: #a9a9a9 !important;
  color: #ffffff !important;
  cursor: not-allowed !important;
  opacity: 0.6;
}
</style>
