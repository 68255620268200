<template>
  <div>
    <OverlayPanel
      ref="overlay"
      :style="overlayStyles"
      class="overlay-with-arrow"
    >
      <div :class="['notification-container', { scrollable: isScrollable }]">
        <div class="notification-header">
          <h3>Notification</h3>
          <i class="pi pi-ellipsis-h"></i>
        </div>

        <div>
          <div
            v-for="(notification, index) in visibleNotifications"
            :key="index"
            class="notification-item"
          >
            <img :src="notification.avatar" class="avatar" alt="User Avatar" />
            <div class="notification-details">
              <p>
                <strong>{{ notification.name }}</strong>
                <span style="color: #9a9fa5">@{{ notification.username }}</span>
              </p>
              <p>
                <span style="color: #6f767e">{{ notification.action }}</span>
                {{ notification.document }}
              </p>
            </div>
            <span class="time">{{ notification.time }}</span>
            <i class="pi pi-circle-fill blue-dot"></i>
          </div>
        </div>

        <primeButton
          @click="toggleScrollable"
          :label="buttonLabel"
          class="custom-notifications-button"
        />
      </div>
    </OverlayPanel>
  </div>
</template>

<script>
import OverlayPanel from "primevue/overlaypanel";
import primeButton from "primevue/button";
import "@/styles/login.css";

export default {
  components: {
    OverlayPanel,
    primeButton,
  },
  data() {
    return {
      notifications: [
        {
          avatar: "https://randomuser.me/api/portraits/men/1.jpg",
          name: "Domenica",
          username: "domenica",
          action: "Commented on",
          document: "Document 1",
          time: "1h",
        },
        {
          avatar: "https://randomuser.me/api/portraits/men/2.jpg",
          name: "Alvaro",
          username: "alvaro",
          action: "Assigned you to",
          document: "Document 2",
          time: "2h",
        },
        {
          avatar: "https://randomuser.me/api/portraits/women/3.jpg",
          name: "Ethel",
          username: "ethel",
          action: "Commented on",
          document: "Document 3",
          time: "8h",
        },
        {
          avatar: "https://randomuser.me/api/portraits/men/4.jpg",
          name: "Tom",
          username: "tom",
          action: "Liked",
          document: "Document 4",
          time: "12h",
        },
        {
          avatar: "https://randomuser.me/api/portraits/men/4.jpg",
          name: "Tom",
          username: "tom",
          action: "Liked",
          document: "Document 4",
          time: "12h",
        },
        {
          avatar: "https://randomuser.me/api/portraits/men/4.jpg",
          name: "Tom",
          username: "tom",
          action: "Liked",
          document: "Document 4",
          time: "12h",
        },
        {
          avatar: "https://randomuser.me/api/portraits/men/4.jpg",
          name: "Tom",
          username: "tom",
          action: "Liked",
          document: "Document 4",
          time: "12h",
        },
      ],
      overlayStyles: {
        width: "422px",
        height: "400px",
        padding: "0",
        borderRadius: "16px",
        backgroundColor: "#111315",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      },
      isScrollable: false,
      buttonLabel: "See all notifications",
    };
  },
  computed: {
    visibleNotifications() {
      return this.isScrollable
        ? this.notifications
        : this.notifications.slice(0, 3);
    },
  },
  methods: {
    toggleScrollable() {
      this.isScrollable = !this.isScrollable;
      this.buttonLabel = this.isScrollable ? "Back" : "See all notifications";
    },
    toggleDropdown(event) {
      this.$refs.overlay && this.$refs.overlay.toggle(event);
    },
  },
};
</script>

<style scoped>
.notification-container {
  padding: 12px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 300px;
  overflow-y: hidden;
  position: relative;
}

.notification-container.scrollable {
  overflow-y: auto;
  max-height: 300px;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: inherit;
  z-index: 1;
  padding-top: 10px;
}

.notification-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.notification-details {
  flex-grow: 1;
}

.time {
  font-size: 12px;
  color: #6f767e;
}

.custom-notifications-button {
  width: 100%;
  margin-top: auto;
}
</style>
