<template>

  <div
    class="text-white   fixed flex flex-col justify-end items-center  top-52 right-0 mb-28 px-16 w-3/4 h-fit transition">
    <component :is="headerComponent"></component>
    <div class="w-full justify-start items-center mb-4 text-2xl font-bold flex ">
      <a :href="route.path">{{
        capitalizeFirstLetter(route.path.split("/")[2])
        }}</a>
      <div v-for="(item, index) in route.query" :key="index" class="flex items-center text-xl text-white/70">
        <NavArrowRight class="text-gray-400" /> <a :href="route.fullPath">{{ capitalizeFirstLetter(item) }}</a>
      </div>
    </div>
    <div class="animate-fade-up w-full h-4/5  bg-gray-900 rounded-2xl border border-gray-950 shadow-2xl shadow-black">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useRoute } from 'vue-router';
import { capitalizeFirstLetter } from '@/utils';
import { NavArrowRight } from '@iconoir/vue';
import { component } from 'vue';

// Access the current route
const route = useRoute();
// Define the props your component accepts
const props = defineProps({
  headerComponent: {
    type: component,
    required: false
  }
});
props;
</script>

<style scoped>
a {
  @apply hover:text-gray-400/50 transition
}
</style>
