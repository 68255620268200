<template>
  <div class="page-container">
    <div class="content-container">
      <div class="logo">
        <h1>
          <img
            style="vertical-align: middle;"
            src="@/assets/logo.svg"
            alt="typeteam logo"
            class="icon"
          />
          typeteam
        </h1>
        <h2 style="color: #ffffff; font-size: 20px">New Password</h2>
        <p style="color: #8d8d8d">
          Please enter your new password.
        </p>
      </div>
      <div class="form">
        <label for="password" class="label">Password</label>
        <input
          type="password"
          v-model="password"
          placeholder="Choose a strong password"
          class="input-field"
        />

        <div class="password-requirements">
          <p><strong>Password should contain:</strong></p>
          <ul>
            <li>At least 8 characters</li>
            <li>Upper and lower case letters</li>
            <li>A symbol (@$%)</li>
          </ul>
        </div>

        <label for="confirmPassword" class="label">Confirm Password</label>
        <input
          type="password"
          v-model="confirmPassword"
          placeholder="Enter your password again"
          class="input-field"
        />
      </div>

      <button @click="resetPassword" class="sign-in-button">
        Confirm
      </button>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from '@/stores/auth';
import { useRouter, useRoute } from 'vue-router';
import { ref } from 'vue';
import '@/styles/login.css';


const authStore = useAuthStore();
const router = useRouter();
const route = useRoute();

const password = ref('');
const confirmPassword = ref('');

const resetPassword = async () => {
  if (password.value !== confirmPassword.value) {
    authStore.errorMessage = 'Passwords do not match';
    return;
  }

  const uid = route.params.uidb64;
  const token = route.params.token;

  await authStore.passwordResetConfirm(uid, token, password.value);

  if (!authStore.errorMessage) {
    router.push('/login'); 
  }
};
</script>