import { defineStore } from "pinia";
import axios from "axios"; // Ensure axios is installed and configured for your backend base URL
import { useAuthStore } from "./auth";

export const useEditorStore = defineStore("editor", {
  state: () => {
    return {
      speakers: null,
      currentSpeaker: null,
      captions: null,
      duration: null,
      errorMessage: null,
      is_loading: false,
    };
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    async fetch(project_id) {
      this.is_loading = true;
      const authStore = useAuthStore();
      const response = await axios.get(
        `/editor/speakers?project=${project_id}`,
        {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );
      this.speakers = response.data;
      const response_captions = await axios.get(
        `/editor/captions?project=${project_id}`,
        {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );
      this.captions = response_captions.data;
      setTimeout(() => {
        this.is_loading = false;
      }, 500);
    },
    async createSpeaker(project_id, name) {
      const authStore = useAuthStore();
      try {
        const response = await axios.post(
          `/editor/speakers/`,
          {
            project: project_id,
            name,
          },
          {
            headers: {
              Authorization: `Bearer ${authStore.token}`,
            },
          }
        );
        this.currentSpeaker = response.data;
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to create speaker";
      }
    },
    async createCaption(
      project_id,
      speaker_id = null,
      text,
      start_timestamp,
      end_timestamp
    ) {
      const authStore = useAuthStore();
      try {
        await axios.post(
          `/editor/captions/`,
          {
            project: project_id,
            speaker: speaker_id || this.currentSpeaker,
            start_timestamp,
            end_timestamp,
            text,
          },
          {
            headers: {
              Authorization: `Bearer ${authStore.token}`,
            },
          }
        );
        this.fetch(project_id);
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to create speaker";
      }
    },
    async deleteCaption(caption) {
      const authStore = useAuthStore();
      try {
        await axios.delete(`/editor/captions/${caption.id}/`, {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        });
        this.fetch(caption.project);
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to create speaker";
      }
    },
    async updateCaption(caption) {
      const authStore = useAuthStore();
      try {
        await axios.put(
          `/editor/captions/${caption.id}/`,
          {
            start_timestamp: caption.start_timestamp,
            end_timestamp: caption.end_timestamp,
            text: caption.text,
            speaker: caption.speaker_full.id,
          },
          {
            headers: {
              Authorization: `Bearer ${authStore.token}`,
            },
          }
        );
        this.fetch(caption.project);
        this.currentSpeaker = caption.speaker_full;
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to create speaker";
      }
    },
    async deleteSpeaker(speaker_id) {
      const authStore = useAuthStore();
      try {
        await axios.delete(`/editor/speakers/${speaker_id}/`, {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        });
        // Refresh speakers list after deletion
        this.speakers = this.speakers.filter((speaker) => speaker.id !== speaker_id);
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to delete speaker";
      }
    },
  },
});