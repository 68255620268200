import { createRouter, createWebHistory } from "vue-router";
import SignUpPage from "@/pages/SignUpPage.vue";
import SignInPage from "@/pages/SignInPage.vue";
import SignUpPageTwo from "@/pages/SignUpPageTwo.vue";
import ResetPassword from "@/pages/ResetPassword.vue";
import ResetPasswordTwo from "@/pages/ResetPasswordTwo.vue";
import NewPassword from "@/pages/NewPassword.vue";
import AdminDashboardPage from "@/pages/dashboard/admin/AdminDashboardPage.vue";
import { adminDashboardChildren } from "./dashboard";
import EditorPage from "@/pages/editor/EditorPage.vue";

const routes = [
  {
    path: "/sign-up",
    name: "SignUpPage",
    component: SignUpPage,
  },
  {
    path: "/sign-up-two",
    name: "SignUpPageTwo",
    component: SignUpPageTwo,
  },
  {
    path: "/sign-in",
    name: "SignInPage",
    component: SignInPage,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/reset-password-two",
    name: "ResetPasswordTwo",
    component: ResetPasswordTwo,
  },
  {
    path: "/",
    redirect: "/sign-in", // Automatically redirect root to Sign In
  },
  {
    path: "/new-password",
    name: "NewPassword",
    component: NewPassword,
  },
  {
    path: "/admin",
    name: "AdminDashboard",
    component: AdminDashboardPage,
    children: adminDashboardChildren,
  },
  {
    path: "/editor/:id",
    name: "Editor",
    component: EditorPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
