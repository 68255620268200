<script setup>
import { ref, watch, onMounted } from "vue";

import {
  MacOptionKey,
  ClosedCaptionsTag,
  Pause,
  Play,
  Search,
  Forward15Seconds,
  Backward15Seconds,
  Undo,
  Redo,
  Translate,
} from "@iconoir/vue";
import { useSongStore } from "../stores/audio";
import Slider from "primevue/slider";
import { storeToRefs } from "pinia";
import { useProjectStore } from "@/stores/project";
const useSong = useSongStore();
const projectStore = useProjectStore();

const { isPlaying, audio } = storeToRefs(useSong);

let isHover = ref(false);
let isTrackTimeCurrent = ref(null);
let isTrackTimeTotal = ref(null);
let seeker = ref(0);
// let seekerContainer = ref(null)
let range = ref(0);

const playFunc = () => {
  useSong.playOrPauseThisSong(projectStore.file);
  return;
};

onMounted(() => {
  if (audio.value) {
    setTimeout(() => {
      timeupdate();
      loadmetadata();
    }, 300);
  }
});

const backward = () => {
  if (audio.value.currentTime < 15) {
    audio.value.currentTime = 0;
  } else {
    audio.value.currentTime -= 15;
  }
};
const forward = () => {
  if (audio.value.duration - audio.value.currentTime > 15) {
    audio.value.currentTime += 15;
  }
};
const timeupdate = () => {
  audio.value.addEventListener("timeupdate", function () {
    var minutes = Math.floor(audio.value.currentTime / 60);
    var seconds = Math.floor(audio.value.currentTime - minutes * 60);
    isTrackTimeCurrent.value =
      minutes + ":" + seconds.toString().padStart(2, "0");
    const value = (100 / audio.value.duration) * audio.value.currentTime;
    range.value = value;
    seeker.value = value;
  });
};
const onSeekerChange = (value) => {
  audio.value.currentTime = value * (audio.value.duration / 100);
};

const loadmetadata = () => {
  audio.value.addEventListener("loadedmetadata", function () {
    const duration = audio.value.duration;
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    isTrackTimeTotal.value =
      minutes + ":" + seconds.toString().padStart(2, "0");
  });
};

watch(
  () => audio.value,
  () => {
    timeupdate();
    loadmetadata();
  }
);

watch(
  () => isTrackTimeCurrent.value,
  (time) => {
    if (time && time == isTrackTimeTotal.value) {
      audio.value.pause();
      audio.value.currentTime = 0;
    }
  }
);
watch(
  () => isHover.value,
  () => {
    if (isHover.value) {
      audio.value.pause();
      isPlaying.value = false;
    }
  }
);
</script>

<template>
  <Slider
    v-model="seeker"
    :model-value="seeker"
    :onmousedown="(isHover = true)"
    :onmouseup="(isHover = false)"
    @change="onSeekerChange"
    :pt="{
      range: 'bg-blue-500',
      // handle: '!bg-transparent !hover:bg-transparent !cursor-default',
      root: 'bg-gray-700',
    }"
    class="w-full animate-fade-up animate-delay-1000 z-10 -mb-1"
  />
  <div
    class="w-full h-32 bg-gray-900 animate-fade-up animate-delay-1000 flex justify-between items-center px-8"
  >
    <div class="flex gap-5 items-center">
      <div
        class="bg-blue-600 rounded-full p-2 text-gray-300 hover:bg-blue-600/80 transition cursor-pointer"
        @click="backward()"
      >
        <Backward15Seconds />
      </div>
      <div
        class="bg-blue-600 rounded-full p-2 text-gray-300 hover:bg-blue-600/80 transition cursor-pointer"
        @click="playFunc()"
      >
        <Play v-if="!isPlaying" class="transition" />
        <Pause v-else class="transition" />
      </div>
      <div
        class="bg-blue-600 rounded-full p-2 text-gray-300 hover:bg-blue-600/80 transition cursor-pointer"
        @click="forward()"
      >
        <Forward15Seconds />
      </div>
    </div>
    <div class="flex !text-gray-300 items-center gap-2">
      <div
        class="rounded-lg border-gray-400 hover:bg-gray-800 border p-1 cursor-pointer transition"
      >
        <Undo />
      </div>
      <div
        class="rounded-lg border-gray-400 hover:bg-gray-800 border p-1 cursor-pointer transition"
      >
        <Redo />
      </div>
      <div
        class="rounded-lg border-gray-400 hover:bg-gray-800 border p-1 cursor-pointer transition"
      >
        <Translate />
      </div>
      <div
        class="rounded-lg border-gray-400 hover:bg-gray-800 border p-1 cursor-pointer transition"
      >
        <ClosedCaptionsTag />
      </div>
      <div
        class="flex items-center gap-1 text-sm font-bold rounded-lg border-gray-400 hover:bg-gray-800 border p-1 cursor-pointer transition"
      >
        <MacOptionKey />
        Shortcuts
      </div>
      <div
        class="flex items-center gap-1 text-sm font-bold rounded-lg border-gray-400 hover:bg-gray-800 border p-1 cursor-pointer transition"
      >
        <Search />
        Find and Replace
      </div>
      <div
        class="flex flex-row items-center gap-1 text-sm font-bold rounded-lg border-gray-400 hover:bg-gray-800 border p-1 cursor-pointer transition"
      >
        <input
          v-if="isTrackTimeCurrent"
          class="bg-transparent w-14 text-center items-center justify-center"
          v-model="isTrackTimeCurrent"
        />
        <input
          value="00:00"
          v-else
          class="bg-transparent w-14 text-center items-center justify-center"
        />
      </div>
    </div>
  </div>
</template>

<style>
:root {
  --p-slider-handle-content-background: #00000000;
  --p-slider-handle-content-hover-background: #00000000;
}

.circle {
  width: 4px;
  height: 4px;
  background-color: rgb(189, 189, 189);
  border-radius: 100%;
}
</style>
