import ProjectsView from '@/pages/dashboard/admin/ProjectsView.vue'
import FilesView from '@/pages/dashboard/admin/FilesView.vue'
import OrdersView from '@/pages/dashboard/admin/OrdersView.vue'
import FinanceView from '@/pages/dashboard/admin/FinanceView.vue'
import ClientsView from '@/pages/dashboard/admin/ClientsView.vue'
import SettingsView from '@/pages/dashboard/admin/SettingsView.vue'
import TrashView from '@/pages/dashboard/admin/TrashView.vue'

const adminDashboardChildren = [
  {
    path: 'projects',
    name: 'DashboardProjects',
    component: ProjectsView
  },
  {
    path: 'files',
    name: 'DashboardFiles',
    component: FilesView
  },
  {
    path: 'orders',
    name: 'DashboardOrders',
    component: OrdersView
  },
  {
    path: 'finance',
    name: 'DashboardFinance',
    component: FinanceView
  },
  {
    path: 'clients',
    name: 'DashboardClients',
    component: ClientsView
  },
  {
    path: 'settings',
    name: 'DashboardSettings',
    component: SettingsView
  },
  {
    path: 'trash',
    name: 'DashboardTrash',
    component: TrashView
  },
];
export { adminDashboardChildren };
// const clientDashboardChildren = [
//   {
//     path: 'orders',
//     name: 'ClientOrders',
//     component: () => import('../views/DashboardHome.vue')
//   },
//   {
//     path: 'settings',
//     name: 'DashboardSettings',
//     component: () => import('../views/DashboardSettings.vue')
//   },
//   {
//     path: 'support',
//     name: 'ClientSupportTickets',
//     component: () => import('../views/DashboardOverview.vue')
//   },
//   {
//     path: 'trash',
//     name: 'ClientTrash',
//     component: () => import('../views/DashboardSettings.vue')
//   }];
// export { adminDashboardChildren, clientDashboardChildren };
