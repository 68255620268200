<template>
  <DashboardBody>
    <div class="flex flex-col w-full h-full">
      <!-- Header -->
      <div class="w-full py-5 px-9 flex justify-between">
          <SearchBar :onChange="search" placeholder="Search Projects" />
        <SelectButton class="dark p-selectbutton" :allowEmpty="false" v-model="value" :options="options"
          aria-labelledby="custom">
          <template #option="slotProps">
            <RouterLink class=" mx-1 font-bold p-2 text-sm text-gray-200 flex items-center gap-3"
              :to="{ path: route.path, query: { filter: slotProps.option } }">
              {{ slotProps.option }}
            </RouterLink>
          </template>
        </SelectButton>
      </div>
      <!-- body -->
      <div class="w-full h-full">
        <div class="p-5">
          <DataTable size="large" :value="projects" paginator :rows="6" tableStyle="min-width: 50rem">

            <template #paginatorprevpagelinkicon>
              Previous
            </template>
            <template #paginatornextpagelinkicon>
              Next
            </template>
            <Column field="id"></Column>
            <Column field="title" header="Project Title"></Column>
            <Column field="status" header="Status"></Column>
            <Column field="length" header="Length"></Column>
            <Column field="date" header="Date Added"></Column>
            <Column field="lastModified" header="Last Edit"></Column>
            <Column header="Progress">
              <template #body="slotProps">
                <ProgressBar :pt="{ value: slotProps.data.color }" :value="slotProps.data.progress" style="height: 7px"
                  :showValue="false" />
              </template>
            </Column>
            <Column header="Action">
              <template #body>
                <Button @click="toggleAction"
                  class="pi pi-ellipsis-v cursor-pointer rounded-lg bg-blue-600 flex items-center justify-center p-2 w-min"
                  aria-haspopup="true" aria-controls="overlay_menu">
                </Button>
                <Menu class="dark text-sm w-min bg-gray-900 !text-gray-100 border-gray-800 " ref="actionState"
                  id="overlay_menu" :model="actionItems" :popup="true" />
              </template>
            </Column>

          </DataTable>
        </div>
      </div>
      <!-- pagination -->
      <!-- <Paginator @page="handlePageChange" :rows="10" :totalRecords="120"> -->
      <!-- </Paginator> -->

    </div>
  </DashboardBody>
</template>


<script setup>
import Menu from 'primevue/menu';
import ProgressBar from 'primevue/progressbar';
import DashboardBody from '@/components/DashboardBody.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import SearchBar from '@/components/SearchBar.vue';
import SelectButton from 'primevue/selectbutton';
// import Paginator from 'primevue/paginator';
import { RouterLink } from 'vue-router';
import { ref } from 'vue';
import { useRoute } from 'vue-router';

const actionState = ref()
const toggleAction = (event) => {
  actionState.value.toggle(event);
}
const actionItems = ref([
  {
    items: [
      {
        label: 'View',
        icon: 'pi pi-window-maximize'
      },
      {
        label: 'Export',
        icon: 'pi pi-download'
      },
      {
        label: 'Share',
        icon: 'pi pi-share-alt'
      },
      {
        label: 'Rename',
        icon: 'pi pi-pencil'
      },
      {
        label: 'Transfer',
        icon: 'pi pi-reply'
      },
      {
        label: 'Move To Trash',
        icon: 'pi pi-trash'
      },
    ]
  }
]);
var route = ref(useRoute());
function search() { }
const value = ref(route.value.query["filter"]);
const options = ref(['Backlog', 'Started', 'Done', 'In Progress']);
// const currentPage = ref(1);

// const handlePageChange = (event) => {
//   currentPage.value = event.page;
//   console.log(`Page changed to: ${event.page}`);
// };

const projects = ref([
  {
    "id": 1,
    "title": "Project 1",
    "status": "Done",
    "length": "00:21:90",
    "date": "2022-01-01",
    "lastModified": "2022-01-01",
    "progress": 80
  },
  {
    "id": 2,
    "title": "Project 2",
    "status": "In Progress",
    "length": "00:30:45",
    "date": "2022-02-15",
    "lastModified": "2022-03-01",
    "progress": 50,
    "color": "bg-yellow-500"

  },
  {
    "id": 3,
    "title": "Project 3",
    "status": "Pending",
    "length": "00:15:30",
    "date": "2022-03-10",
    "lastModified": "2022-03-10",
    "progress": 10,
    "color": "bg-red-500"
  },
  {
    "id": 4,
    "title": "Project 4",
    "status": "Done",
    "length": "00:45:00",
    "date": "2022-04-05",
    "lastModified": "2022-04-05",
    "progress": 100
  },
  {
    "id": 5,
    "title": "Project 5",
    "status": "In Progress",
    "length": "00:10:15",
    "date": "2022-05-20",
    "lastModified": "2022-06-01",
    "progress": 30,
    "color": "bg-red-500"
  },
  {
    "id": 6,
    "title": "Project 6",
    "status": "Done",
    "length": "00:25:00",
    "date": "2022-07-15",
    "lastModified": "2022-07-15",
    "progress": 90
  },
  {
    "id": 7,
    "title": "Project 7",
    "status": "Pending",
    "length": "00:35:20",
    "date": "2022-08-10",
    "lastModified": "2022-08-10",
    "progress": 5,
    "color": "bg-red-500"
  },
  {
    "id": 8,
    "title": "Project 8",
    "status": "In Progress",
    "length": "00:50:10",
    "date": "2022-09-01",
    "lastModified": "2022-09-15",
    "progress": 60
  },
  {
    "id": 9,
    "title": "Project 9",
    "status": "Done",
    "length": "01:00:00",
    "date": "2022-10-20",
    "lastModified": "2022-10-20",
    "progress": 100
  },
  {
    "id": 10,
    "title": "Project 10",
    "status": "In Progress",
    "length": "00:40:30",
    "date": "2022-11-05",
    "lastModified": "2022-11-10",
    "progress": 75
  }
]

)
</script>

<style>
:root {
  --p-menu-item-color: #6f767e;
  --p-paginator-nav-button-selected-background: #292929;
  --p-datatable-header-border-color: #292929;
  --p-datatable-header-cell-background: #00000000;
  --p-datatable-row-background: #00000000;
  --p-datatable-header-cell-color: #6f767e;
  --p-datatable-row-color: #F1F2F3;
  --p-datatable-border-color: #272B30;
}

.p-paginator {
  @apply bg-transparent text-gray-400 my-3
}

.p-paginator-first {
  @apply hover:bg-transparent
}

.p-paginator-last {
  @apply hover:bg-transparent
}

.p-paginator .p-paginator-page {
  @apply hover:bg-gray-800/40 rounded-lg text-gray-400
}

::v-deep .p-selectbutton {
  background-color: #00000000 !important;
}
</style>
