<template>
  <Toast />
  <div
    class="text-gray-400 block animate-fade-right fixed gap-5 left-0 top-0 w-1/4 h-full px-7 py-3 min-w-fit shadow-black shadow-2xl bg-gray-900 z-30"
  >
    <div class="flex flex-col items-center justify-between h-full w-full">
      <IconoirProvider
        :icon-props="{
          'stroke-width': 1,
          width: '1.5em',
          height: '1.5em',
        }"
      >
        <!-- sidebar header -->
        <DashboardSidebarHeader />
        <!-- admin sidebar items -->
        <div v-if="is_admin" class="w-full">
          <Accordion class="w-full gap">
            <AccordionPanel class="w-full bg-transparent border-none">
              <AccordionHeader
                class="flex hover:bg-gray-100/10 bg-transparent text-gray-400 cursor-pointer w-full rounded-lg p-3 justify-between items-center transition"
              >
                <div class="flex">
                  <TextBox />
                  <span class="ml-3">Projects</span>
                </div>
                <div class="w-min ml-auto mr-2 text-center">
                  <PlusCircle />
                </div>
              </AccordionHeader>
              <AccordionContent class="w-full items-start pl-3">
                <RouterLink
                  @click="select_item(item.text)"
                  :to="{ path: item.href, query: { filter: item.text } }"
                  :class="[
                    'w-full text-gray-400/80 cursor-pointer rounded-lg  hover:bg-gray-100/10 items-start flex p-3 justify-between transition my-1',
                    selected_item === item.text ? 'bg-gray-200/20' : '',
                  ]"
                  v-for="(item, index) in projects_filter"
                  :key="index"
                  :onClick="item.onClick"
                >
                  <div class="flex items-center gap-3">
                    <ComponentSolid width="12" height="12" />
                    {{ item.text }}
                  </div>
                  <div
                    v-if="item.notifications"
                    class="text-black rounded-lg bg-green-300 px-2"
                  >
                    {{ item.notifications }}
                  </div>
                </RouterLink>
              </AccordionContent>
            </AccordionPanel>
            <RouterLink
              @click="select_item(item.text)"
              :to="{ path: item.href }"
              v-for="(item, index) in admin_items"
              :key="index"
              :class="[
                'mt-2 flex flex-row hover:bg-gray-100/10 cursor-pointer w-full rounded-lg p-3 justify-between items-center transition',
                selected_item === item.text ? 'bg-gray-200/20' : '',
              ]"
            >
              <div class="flex">
                <component :is="item.icon"></component>
                <span class="ml-3">{{ item.text }}</span>
              </div>
              <div class="text-center">
                <component :is="item.suffix"></component>
              </div>
            </RouterLink>
          </Accordion>
        </div>
        <!-- client sidebar items -->
        <div v-if="!is_admin">client</div>

        <!-- footer -->
        <div class="text-2xl mb-4 mt-16 flex justify-center w-full">
          <div class="flex gap-2">
            <img src="/logo.svg" alt="" />
            <div>typeteam</div>
          </div>
          <div class="text-sm ml-2">Pro 💎</div>
        </div>
      </IconoirProvider>
    </div>
  </div>
  <!-- invite dialog -->
  <Dialog
    :pt="{
      root: 'bg-gray-900 border-none w-2/5 text-gray-400 p-8',
      header: 'm-5',
      content: 'mx-5',
      mask: '!bg-black/50   backdrop-blur-sm',
    }"
    v-model:visible="dialog_visible"
    dismissableMask
    modal
    header="Invite Users"
  >
    <span class="text-surface-500 dark:text-surface-400 block"
      >Add colleagues and collaborators to your workspace.
    </span>
    <div class="w-full flex gap-2 my-5">
      <InputGroup
        class="w-full py-2 px-4 shadow-2xl border border-gray-400/70 shadow-black/60 bg-gray-900 rounded-xl"
      >
        <InputText
          class="border-none px-2 text-gray-400 placeholder:text-gray-300/50 bg-transparent w-full"
        />
        <InputGroupAddon class="bg-transparent border-none">
          alskdjg
        </InputGroupAddon>
      </InputGroup>
      <div
        class="bg-blue-600 hover:bg-blue-500 flex text-gray-300 items-center justify-center rounded-xl px-9 py-1 cursor-pointer transition"
      >
        Invite
      </div>
    </div>
    <span class="text-surface-500 dark:text-surface-400 block">
      Type or paste multiple email addresses seperated by comma (,)
    </span>
    <Divider />
    <div class="flex items-center gap-4 mb-4 text-gray-200 font-bold text-xl">
      Invite Link
    </div>
    <div class="mb-9">Add colleagues and collaborators via a invite link.</div>
    <div class="flex items-center gap-4 mb-4 text-gray-200 text-lg">
      Guest Link
    </div>
    <div class="my-3 mb-9">
      <InputGroup>
        <InputText disabled value="https://typeteam.com/invite" />
        <InputGroupAddon @click="copy_clicked()">
          <Copy /> Copy
        </InputGroupAddon>
      </InputGroup>
    </div>
    <div class="flex items-center gap-4 mb-4 text-gray-200 text-lg">
      Member Link
    </div>
    <div class="my-3 mb-9">
      <InputGroup>
        <InputText disabled value="https://typeteam.com/invite" />
        <InputGroupAddon @click="copy_clicked()">
          <Copy /> Copy
        </InputGroupAddon>
      </InputGroup>
    </div>
  </Dialog>
</template>

<script setup>
import {
  Copy,
  Archive,
  ShoppingBagArrowUp,
  ComponentSolid,
  MoneySquare,
  UserCrown,
  Settings,
  BinHalf,
  UserPlus,
  PlusCircle,
  IconoirProvider,
  TextBox,
} from "@iconoir/vue";
import Toast from "primevue/toast";
import Accordion from "primevue/accordion";
import AccordionPanel from "primevue/accordionpanel";
import AccordionHeader from "primevue/accordionheader";
import AccordionContent from "primevue/accordioncontent";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import { defineProps, ref } from "vue";
import DashboardSidebarHeader from "./DashboardSidebarHeader.vue";
import { RouterLink } from "vue-router";
import { useToast } from "primevue/usetoast";

const toast = useToast();
const props = defineProps({
  is_admin: {
    type: Boolean,
    required: true,
    default: true,
  },
});
const projects_filter = ref([
  {
    text: "Backlog",
    onClick: () => {},
    href: "/admin/projects",
  },
  {
    text: "Started",
    onClick: () => {},
    href: "/admin/projects",
  },
  {
    text: "Done",
    notifications: 5,
    onClick: () => {},
    href: "/admin/projects",
  },
  {
    text: "In Progress",
    onClick: () => {},
    href: "/admin/projects",
  },
]);

const admin_items = ref([
  {
    text: "Archive and Files",
    icon: Archive,
    onClick: () => {},
    href: "/admin/files",
  },
  {
    text: "Invite",
    icon: UserPlus,
    onClick: () => {},
    suffix: PlusCircle,
  },
  {
    text: "Orders",
    icon: ShoppingBagArrowUp,
    onClick: () => {},
    href: "/admin/orders",
  },
  {
    text: "Finance",
    icon: MoneySquare,
    onClick: () => {},
    href: "/admin/finance",
  },
  {
    text: "Clients",
    icon: UserCrown,
    onClick: () => {},
    href: "/admin/clients",
  },
  {
    text: "Settings",
    icon: Settings,
    onClick: () => {},
    href: "/admin/settings",
  },
  {
    text: "Trash Bin",
    icon: BinHalf,
    onClick: () => {},
    href: "/admin/trash",
  },
]);
const selected_item = ref();
const dialog_visible = ref(false);
const select_item = async (item) => {
  if (item === "Invite") {
    dialog_visible.value = true;
    return;
  }
  selected_item.value = item;
};
const copy_clicked = () => {
  toast.add({
    severity: "success",
    summary: "Copied",
    detail: "invite link copied",
    life: 3000,
  });
  // toast.add({ severity: 'success', summary: 'Success Message', detail: 'Message Content', life: 3000 });
};
props;
</script>

<style scoped>
.p-accordion {
  --p-accordion-content-background: #00000000;
}

.p-inputgroup {
  @apply w-full py-2 px-4 shadow-2xl border border-gray-400/70 shadow-black/60 bg-gray-900 rounded-xl;
}

.p-inputgroupaddon {
  @apply border-none bg-transparent hover:text-gray-200 cursor-pointer transition;
}

.p-inputtext {
  @apply border-none bg-transparent;
}

.p-accordioncontent {
  background-color: #00000000;
}
</style>
