<template>
  <div class="page-container">
    <div class="content-container">
      <div class="logo">
        <h1><img style="vertical-align: middle;" src="@/assets/logo.svg" alt="typeteam logo" class="icon" />typeteam</h1>
        <h2 style='color:#FFFFFF;font-size:20px'>Reset Password</h2>
        <p style="color:#8D8D8D;">If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.</p>
      </div>

      <button @click="sendResetInstructions" class="sign-in-button">Back To Login</button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import '@/styles/login.css'; 

export default {
  name: 'ResetPasswordTwo',
  setup() {
    const email = ref('');
    const router = useRouter();

    const sendResetInstructions = () => {
      
        router.push({ name: 'SignInPage' });
      
    };

    return {
      email,
      sendResetInstructions
    };
  }
};
</script>
