<template>
  <InputGroup class="w-[18rem] shadow-2xl border-none shadow-black/60 bg-gray-800 rounded-xl">
    <InputGroupAddon class="border-none bg-transparent   border-gray-500/80 ">
      <Search width="2em" height="2em" class="cursor-pointer hover:text-gray-400/60 transition" />
    </InputGroupAddon>
    <InputText @input="handleInputChange" :placeholder="placeholder" unstyled
      class="border-gray-500/80 text-gray-400 placeholder:text-gray-300/50 bg-transparent w-full" />
  </InputGroup>
</template>

<script setup>
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputText from 'primevue/inputtext';
import { Search } from '@iconoir/vue';
import { defineProps } from 'vue';

var props = defineProps({
  onChange: {
    type: Function,
    required: true
  },
  placeholder: {
    type: String,
    default: "Search"
  }
});

const handleInputChange = (event) => {
  props.onChange(event.target.value);
};
</script>

<style lang="scss" scoped></style>
