import { defineStore } from "pinia";
import { useAuthStore } from "./auth";
import axios from "axios";

export const useFileStore = defineStore("file", {
  state: () => {
    return {
      id: null,
      upload_file: null,
      files: null,
      errorMessage: null,
      is_loading: false,
      uploadProgress: 0, // New state for upload progress
    };
  },
  actions: {
    async upload(file, type, id) {
      const authStore = useAuthStore();
      try {
        const formData = new FormData();
        formData.append("title", file.name);
        formData.append("media_type", type);
        formData.append("file", file);
        this.is_loading = true;
        this.uploadProgress = 0; // Reset progress to 0 before upload starts

        const response = await axios.post(`/file/${id}/`, formData, {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            ); // Calculate the progress percentage
          },
        });

        this.id = response.data.id;
        this.is_loading = false;
        this.uploadProgress = 100; // Set to 100% upon completion
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to create project";
        this.is_loading = false;
        this.uploadProgress = 0; // Reset progress if there's an error
      }
    },
    
  },
});

